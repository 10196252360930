import React, {ReactElement} from 'react'
import Nav from './Nav'
import Logo from './Logo'
import styled from 'styled-components'

import agent from './../images/agent.png'
import phone from './../images/phone.png'
import Button from './Button'

import BackgroundImage from 'gatsby-background-image'
import {graphql, useStaticQuery} from 'gatsby'

const StyledBackground = styled(BackgroundImage)`
  width: 100%;
  height: 100%;
  width: 100%;

  display: grid;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  .tint {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.6);
    z-index: 1;
  }

  min-height: 600px;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  max-width: 100vw;
  overflow: hidden;

  @media only screen and (min-width: 600px) {
    background-position-y: -300px;
  }
`

const BackgroundSection = ({
  children,
}: {
  children: ReactElement
}): ReactElement => {
  const data = useStaticQuery(graphql`
    query {
      desktop: file(relativeDirectory: {eq: "gallery"}, name: {eq: "hero2"}) {
        childImageSharp {
          fluid(quality: 64, maxHeight: 1024) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  console.log()

  return (
    <StyledBackground
      className={'image'}
      Tag="section"
      fluid={data.desktop.childImageSharp.fluid}
    >
      <>
        <div className={'tint'}></div>
        {children}
      </>
    </StyledBackground>
  )
}

const HeaderContainerStyles = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  display: block;
  max-width: 1200px;
  height: 100%;
  margin: 0 auto;
`

const HeaderStyles = styled.header`
  grid-area: header;
  overflow: hidden;
`

const StyledHeaderContent = styled.div`
  .address {
    position: absolute;
    display: block;
    padding-left: 2rem;
    padding-top: 100px;
    z-index: 4;
    color: var(--white);

    h1 {
      font-size: var(--primary-header-font-size);
      font-weight: 300;
      text-shadow: 0px 1px 0px #000;
    }

    @media only screen and (min-width: 600px) {
      padding-left: 0;
      padding-top: 250px;
    }

    .info {
      margin-top: 3rem;
      font-size: var(--info-font-size);

      ul {
        list-style-type: none;
        margin-left: 0;
        padding: 0;
      }

      li {
        display: inline-flex;
        padding: 0 2rem 0 0;

        img,
        span {
          flex: 1;
        }

        a {
          text-decoration: none;
          color: var(--white);
        }

        span {
          strong {
            display: block;
            font-size: var(--base-font-size);
            white-space: nowrap;
          }

          margin-left: 1rem;
          white-space: nowrap;
        }
      }
    }
  }

  .priceInfo {
    position: absolute;
    left: 2rem;
    display: block;
    padding-top: 350px;
    color: var(--white);
    width: 300px;
    text-align: left;
    z-index: 2;
    font-weight: 300;
    font-size: 2.9rem;

    @media only screen and (min-width: 600px) {
      left: unset;
      right: 0;
      padding-top: 300px;
    }

    span.from {
      display: block;
      text-shadow: 0px 1px 0px #000;
    }

    span.price {
      text-shadow: 0px 1px 0px #000;
      margin-bottom: 3rem;
      display: block;

      strong {
        font-size: 8.5rem;
        margin-right: 1rem;
      }
    }
  }
`

const Header = (): ReactElement => (
  <HeaderStyles>
    <BackgroundSection>
      <StyledHeaderContent>
        <HeaderContainerStyles>
          <>
            <Logo />
            <Nav />
            <div className="address">
              <h1>
                Smith Cottages Langport
                <br></br>
                Somerset
                <br />
                United Kingdom
              </h1>
              <div className="info">
                <ul>
                  <li>
                    <img src={agent} alt={'Your hostess will be Julie'} />
                    <span>
                      <strong>Host</strong>Julie
                    </span>
                  </li>
                  <li>
                    <img
                      src={phone}
                      alt={'You can contact us at 07786 012 176'}
                    />
                    <span>
                      <strong>Call us on</strong>
                      <a href="tel:+447786 012 176">(+44) 7786 012 176</a>
                    </span>
                  </li>
                </ul>
              </div>
            </div>
            <div className="priceInfo">
              <span className="from">From</span>
              <span className="price">
                <strong>£50</strong>/night
              </span>
              <Button
                isExternal={true}
                link="mailto:smithcottageslangport@gmail.com?subject=Cottage Availability"
                text={'Click here to email us'}
              />
            </div>
          </>
        </HeaderContainerStyles>
      </StyledHeaderContent>
    </BackgroundSection>
  </HeaderStyles>
)

export default Header
