import React, {ReactElement} from 'react'
import {useStaticQuery, graphql} from 'gatsby'
import Img from 'gatsby-image'
import styled from 'styled-components'
/*
 * This component is built using `gatsby-image` to automatically serve optimized
 * images with lazy loading and reduced file sizes. The image is loaded using a
 * `useStaticQuery`, which allows us to load the image from directly within this
 * component, rather than having to pass the image data down from pages.
 *
 * For more information, see the docs:
 * - `gatsby-image`: https://gatsby.dev/gatsby-image
 * - `useStaticQuery`: https://www.gatsbyjs.com/docs/use-static-query/
 */

const RatingsStyles = styled.ul`
  --green: #66914b;
  display: block;
  height: 50px;
  width: auto;
  overflow: hidden;
  line-height: 50px;
  display: grid;
  grid-template-columns: auto repeat(5, 1fr);
  padding: 0;
  list-style: none;

  li.logo {
    margin-left: 0;
  }

  li.star {
    height: 100%;
    margin-left: 0;
    vertical-align: top;

    span {
      position: relative;
      display: block;
      background-color: var(--green);
      width: 26px;
      height: 26px;
      margin: 12px 0 12px 2px;
      border-radius: 50%;
      z-index: 5;

      &::before {
        content: '';
        position: absolute;
        top: 3px;
        left: 3px;
        right: 3px;
        bottom: 3px;
        width: 20px;
        height: 20px;
        background-color: var(--white);
        border-radius: 50%;
        z-index: 6;
      }

      &::after {
        content: '';
        position: absolute;
        top: 7px;
        left: 7px;
        right: 7px;
        bottom: 7px;
        width: 12px;
        height: 12px;
        background-color: var(--green);
        border-radius: 50%;
        z-index: 7;
      }
    }
  }
`

const Tripadvisor = (): ReactElement => {
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: {eq: "tripadvisor.png"}) {
        childImageSharp {
          fixed(height: 50) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
    }
  `)

  return (
    <RatingsStyles>
      <li className={'logo'}>
        {!data?.placeholderImage?.childImageSharp?.fixed ? (
          <div>Picture not found</div>
        ) : (
          <Img
            fixed={data.placeholderImage.childImageSharp.fixed}
            alt={'Read our 5 star reviews on Tripadvisor'}
          />
        )}
      </li>
      <li className={'star'}>
        <span>&nbsp;</span>
      </li>
      <li className={'star'}>
        <span>&nbsp;</span>
      </li>
      <li className={'star'}>
        <span>&nbsp;</span>
      </li>
      <li className={'star'}>
        <span>&nbsp;</span>
      </li>
      <li className={'star'}>
        <span>&nbsp;</span>
      </li>
    </RatingsStyles>
  )
}

export default Tripadvisor
