import React from 'react'
import styled from 'styled-components'
import {Link} from 'gatsby'

const ButtonStyles = styled.div`
  a {
    display: inline-block;
    background-color: var(--yellow);
    padding: 1rem 3rem;
    border-radius: 2rem;
    color: black;
    font-weight: bold;
    font-size: var(--button-font-size);
    transform: all 0.2s ease-in-out;

    &::hover {
      background-color: var(--yellow);
    }
  }
`

export interface ButtonProps {
  isExternal?: boolean
  link: string
  text: string
}

const Button = ({link, text, isExternal = false}: ButtonProps) => {
  return (
    <ButtonStyles>
      {isExternal ? <a href={link}>{text}</a> : <Link to={link}>{text}</Link>}
    </ButtonStyles>
  )
}

export default Button
