import React from 'react'
import Layout from './src/components/Layout'

export function wrapPageElement({element: children, props}) {
  return <Layout {...props}>{children}</Layout>
}

export const onClientEntry = () => {
  // IntersectionObserver polyfill for gatsby-background-image (Safari, IE)
  if (!(`IntersectionObserver` in window)) {
    import(`intersection-observer`)
    console.log(`# IntersectionObserver is polyfilled!`)
  }
}
