import {Link} from 'gatsby'
import {AnchorLink} from 'gatsby-plugin-anchor-links'
import React, {ReactElement} from 'react'
import styled from 'styled-components'
import Tripadvisor from './Tripadvisor'

import facebookIcon from './../images/facebook.svg'

export interface NavProps {
  siteTitle: string
}

const NavStyles = styled.nav`
  position: absolute;
  top: 2rem;
  right: 2rem;
  z-index: 2;

  @media only screen and (min-width: 600px) {
    top: 4rem;
    right: 0;
  }

  ul.nav {
    display: grid;
    margin: 0;
    padding: 0;

    list-style-type: none;
    text-align: right;
    grid-template-rows: 50px 50px;
    grid-template-columns: repeat(3, auto);
    grid-template-areas:
      'facebook tripadvisor tripadvisor'
      '.        about       contact    ';
    grid-row-gap: 3rem;
    grid-column-gap: 3rem;
    justify-content: center;
    align-items: center;

    @media only screen and (min-width: 600px) {
      grid-template-rows: auto;
      grid-template-columns: repeat(5, auto);
      grid-template-areas: 'home about contact tripadvisor facebook';
    }
  }

  li {
    a {
      display: block;
      height: 100%;
      width: 100%;
      color: var(--white);
      font-weight: bold;
      text-align: right;
      text-shadow: 0px 1px 0px #333;
    }
  }

  li.homeLink {
    display: none;
    @media only screen and (min-width: 600px) {
      display: block;
      grid-area: home;
    }
  }

  li.aboutLink {
    grid-area: about;
  }
  li.contactLink {
    grid-area: contact;
  }
  li.facebookLink {
    grid-area: facebook;
  }
  li.tripAdvisorLink {
    grid-area: tripadvisor;
  }

  img.facebookIcon {
    height: 50px;
  }
`

function Nav(): ReactElement {
  return (
    <NavStyles>
      <ul className={'nav'}>
        <li className={'homeLink'}>
          <Link to="/">Home</Link>
        </li>
        <li className={'aboutLink'}>
          <AnchorLink to="/#about">About Us</AnchorLink>
        </li>
        <li className={'contactLink'}>
          <AnchorLink to="/#contact">Contact Us</AnchorLink>
        </li>
        <li className={'facebookLink'}>
          <a href="https://www.facebook.com/smithcottageslangport/">
            <img
              className={'facebookIcon'}
              src={facebookIcon}
              alt="Visit our facebook page"
            />
          </a>
        </li>
        <li className={'tripAdvisorLink'}>
          <a href="https://www.tripadvisor.co.uk/RentalPropertyManager-a_manager.470639">
            <Tripadvisor />
          </a>
        </li>
      </ul>
    </NavStyles>
  )
}

export default Nav
