import React, {ReactElement} from 'react'
import styled from 'styled-components'

import Button from './Button'

import BackgroundImage from 'gatsby-background-image'
import {graphql, useStaticQuery} from 'gatsby'

const StyledBackground = styled(BackgroundImage)`
  width: 100%;
  height: 100%;
  width: 100%;

  display: grid;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  .tint {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.6);
    z-index: 1;
  }

  min-height: 600px;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  max-width: 100vw;
  overflow: hidden;

  @media only screen and (min-width: 600px) {
    background-position-y: -300px;
  }
`

const BackgroundSection = ({
  children,
}: {
  children: ReactElement
}): ReactElement => {
  const data = useStaticQuery(graphql`
    query {
      desktop: file(relativePath: {eq: "garden.jpeg"}) {
        childImageSharp {
          fluid(quality: 64, maxHeight: 1024) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  return (
    <StyledBackground
      className={'image'}
      Tag="section"
      fluid={data.desktop.childImageSharp.fluid}
    >
      <>
        <div className={'tint'}></div>
        {children}
      </>
    </StyledBackground>
  )
}

const StyledFooterContent = styled.div`
  z-index: 2;
  text-align: center;
  display: block;

  justify-self: center;
  align-self: center;

  p {
    display: block;
    text-align: center;
    color: var(--white);
    margin: 1.5rem auto;
    font-size: var(--paragraph-font-size);
    max-width: 600px;
  }

  header {
    margin: 0;
    justify-self: center;
    align-self: center;
    font-size: var(--primary-header-font-size);
    color: var(--white);
    margin-bottom: 1.5rem;
  }
`

const Footer = (): ReactElement => {
  return (
    <>
      <footer>
        <BackgroundSection>
          <StyledFooterContent>
            <header>Interested in staying?</header>
            <Button
              isExternal={true}
              link="mailto:smithcottageslangport@gmail.com?subject=Cottage Availability"
              text={'Click here to email us'}
            />
          </StyledFooterContent>
        </BackgroundSection>
      </footer>
      <div className={'copyright'}>
        Copyright © {new Date().getFullYear()}. All Rights reserved
      </div>
    </>
  )
}

export default Footer
