import {createGlobalStyle} from 'styled-components'

/*
  .             .       .                       .                    nav(18px)
  header(41px)  .       .                       from(29px)           price(85px)

  header(41px)  .       .                       .                    .
  p(15px)       .       .                       .                    .
  button(13px)  .       .                       .                    .

  .                     . sectionHeader(41px)   .                    .

  ------------------------- Property Images --------------------------  

  propertyHeaders(26px) . propertyHeaders(26px) .                    propertyHeaders(26px)
  propertyInfo(14px)    . propertyInfo(14px)    .                    propertyInfo(14px)
  amenities(13px)       . amenities(13px)       .                    amenities(13px)
  exploreMore(13px)     . exploreMore(13px)     .                    exploreMore(13px)

  .                     . .                     sectionHeader(41px)  .
  .                     . .                     trustInfo(14px)      .
  .                     . .                     trustSubHeader(18px) .
  .                     . .                     trustLocations(14px) .
  sectionHeader(41px)   . .                     sectionHeader(41px)  .
  sectionInfo(14px)     . .                     sectionInfo(14px)    .

  .                     . sectionHeader(41px)   .                    .

  -------------------------- Gallery Images --------------------------  

  .                     . sectionHeader(41px)   .                    .
  .                     . button(13px)          .                    .

  .                     . sectionHeader(41px)   .                    .
  -------- map -------- . address(14px)         email(14px)          phone(14px)

  .                     . sectionHeader(41px)   .                    .
  .                     . footerText(13px)      .                    .
  .                     . button(13px)          .                    .

  .                     . copyright(14px)   .                    .

*/

const Typography = createGlobalStyle`
  :root {
    --base-font-size: 1.5rem;
    --primary-header-font-size: 3.2rem;
    --secondary-header-font-size: 2.3rem;
    --info-font-size: 1.2rem;
    --paragraph-font-size: 1.3rem;
    --button-font-size: 1.3rem;
    
    --info-line-height: 2rem;
    --paragraph-line-height: 2.5rem;

    @media only screen and (min-width: 600px) {
      --base-font-size: 1.8rem;
      --primary-header-font-size: 4.1rem;
      --secondary-header-font-size: 2.6rem;
      --info-font-size: 1.3rem;
      --paragraph-font-size: 1.5rem;

    }
  }

  html {
    font-family: Montserrat, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-display: swap;
    color: var(--black);
  }
  p, li {
    letter-spacing: 0.5px;
  }
  h1,h2,h3,h4,h5,h6 {
    font-weight: normal;
    margin: 0;
  }
  a {
      text-decoration: none;
  }

  .center {
    text-align: center;
  }
`

export default Typography
