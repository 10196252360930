/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React, {ReactNode, ReactElement} from 'react'
import styled from 'styled-components'

import Header from './Header'
import Footer from './Footer'

import GlobalStyles from '../styles/GlobalStyles'
import Typography from '../styles/Typography'

import 'normalize.css'

export interface LayoutProps {
  children: ReactNode
}

const Layout = ({children}: LayoutProps): ReactElement => {
  const LayoutStyles = styled.div`
    margin: 0;
    padding: 0;
    background-color: var(--white);
    width: 100vw;

    display: grid;
    grid-auto-rows: 768px auto auto auto;
    grid-template-areas:
      'header'
      'main'
      'footer'
      'copyright';

    main {
      grid-area: main;
    }

    footer {
      grid-area: footer;
    }

    .copyright {
      grid-area: copyright;
      background-color: var(--white);
      color: var(--darkgrey);
      text-align: center;
      padding: 1.5rem 0;
      font-size: var(--paragraph-font-size);

      @media only screen and (min-width: 600px) {
        padding: 2rem 0;
      }
    }
  `

  return (
    <LayoutStyles>
      <GlobalStyles />
      <Typography />
      <Header />
      <main>{children}</main>
      <Footer />
    </LayoutStyles>
  )
}

export default Layout
