import React from 'react'
import {useStaticQuery, graphql} from 'gatsby'
import Img from 'gatsby-image'
import styled from 'styled-components'
/*
 * This component is built using `gatsby-image` to automatically serve optimized
 * images with lazy loading and reduced file sizes. The image is loaded using a
 * `useStaticQuery`, which allows us to load the image from directly within this
 * component, rather than having to pass the image data down from pages.
 *
 * For more information, see the docs:
 * - `gatsby-image`: https://gatsby.dev/gatsby-image
 * - `useStaticQuery`: https://www.gatsbyjs.com/docs/use-static-query/
 */

const LogoStyles = styled.div`
  background-color: var(--yellow);
  width: 100px;
  border-bottom-left-radius: 1rem;
  border-bottom-right-radius: 1rem;
  padding: 20px 15px;
  z-index: 5;
  position: relative;
  margin-left: 2rem;

  @media only screen and (min-width: 600px) {
    width: 150px;
    padding: 20px 15px;
    margin-left: 0;
  }

  &::before {
    content: '';
    position: absolute;
    top: -10px;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background-color: var(--white);
    border-bottom-left-radius: 1rem;
    border-bottom-right-radius: 1rem;
    z-index: -1;
  }
`

const Logo = () => {
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: {eq: "logo.png"}) {
        childImageSharp {
          fluid(quality: 64, maxWidth: 150) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  return (
    <LogoStyles>
      {!data?.placeholderImage?.childImageSharp?.fluid ? (
        <div>Picture not found</div>
      ) : (
        <Img
          alt={'Smith Cottages Langport company logo'}
          fluid={data.placeholderImage.childImageSharp.fluid}
        />
      )}
    </LogoStyles>
  )
}

export default Logo
