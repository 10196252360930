import {createGlobalStyle} from 'styled-components'

const GlobalStyles = createGlobalStyle`
  :root {
    --red: #FF4949;
    --black: #2E2E2E;
    --lightgrey: #f8f8f8;
    --darkgrey: #999;
    --yellow: #D8C63E;
    --darkyellow: #BEA909;
    --white: #fff;
    --grey: #efefef;
    --green: #00B164;
    --headergreen: #018665;
    --darkgreen: #388367;
    --linkgreen: #0DE285;

    --above-mobile-breakpoint: 768px;
  }

  html {
  box-sizing: border-box;
  }
  *, *:before, *:after {
    box-sizing: inherit;
  }

  html {
    font-size: 10px;
    width: 100vw;
  }

  body {
    font-size: var(--base-font-size);
    width: 100vw;
  }

  fieldset {
    border-color: rgba(0,0,0,0.1);
    border-width: 1px;
  }

  button {
    background: var(--red);
    color: white;
    border: 0;
    padding: 0.6rem 1rem;
    border-radius: 2px;
    cursor: pointer;
    --cast: 2px;
    box-shadow: var(--cast) var(--cast) 0 var(--grey);
    text-shadow: 0.5px 0.5px 0 rgba(0,0,0,0.2);
    transition: all 0.2s;
    font-size: var(--button-font-size);
    &:hover {
      --cast: 4px;
    }
  }

  .gatsby-image-wrapper img[src*=base64] {
    image-rendering: -moz-crisp-edges;
    image-rendering: pixelated;
  }

  img {
    max-width: 100%;
  }

`

export default GlobalStyles
